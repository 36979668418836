@import "../assets/fonts/fonts";

/* Font */

$body-font-family: 'avertaregular';
$heading-font-family: 'avertabold';

$fs-modal-title: 28px;
$fs-sm-default: 14px;
$fs-default: 16px;
$fs-mob-title-medium: 22px;
$fs-mob-title-large: 28px;
$fs-des-title-medium: 24px;
$fs-des-title-large: 40px;

/* Color */
$white: #fff;
$black: #000;
$grey: #7f7f7f;
$red-orange: #efa693;
$red-orange-light: #fcf2ef;
$red: #890909f0;


/* Border Color */
$border-grey: rgba(151, 151, 151, 0.37);
$border-red-orange: $red-orange;
$border-red-orange: #fcc9ad;

/* BreakPoints */
$mob-width: 320px;
$tablet-width: 768px;
$desktop-small-width: 992px;
$desktop-width: 1024px;
$desktop-lg-width: 1200px;
$desktop-xxl-width: 1400px;