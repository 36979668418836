@import "../../scss/main.scss";

/**
* Footer styles
**/
footer {
  text-align: left;
  body.lang-ar & {
    text-align: right;
  }
  .top-blk {
    background: $black;
    padding: 45px 0 61px;
    .row {
      align-items: center;
    }
    .footer-logo-wrap {
      display: inline-flex;
    }
    .footer-logo {
      width: 81px;
    }

    body.lang-ar & {
      text-align: right;
    }
  }

  .right-blk {
    .download {
      display: inline-block;
      text-indent: -9999px;
      height: 40px;
      margin-bottom: 10px;
      width: 131px;
    }
    .google-play {
      background: url("../../assets/images/google-play.png") no-repeat 0 0 /
        contain;
      margin-right: 24px;
      body.lang-ar & {
        margin: 0 0 0 24px;
      }
    }
    .app-store {
      background: url("../../assets/images/app-store.png") no-repeat 0 0 /
        contain;
    }
    .title {
      color: $white;
      margin: 25px 0 24px;
      font: {
        family: $heading-font-family;
        size: $fs-des-title-medium;
      }
    }
  }

  .contact-inner-blk {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    > div:not(:first-child) {
      flex: 1;
      padding-top: 30px;
    }
    div:nth-child(1) {
      @include border(bottom, 1px, solid, rgba(239, 166, 147, 0.3));
      padding-bottom: 30px;
      width: 100%;
    }
  }

  .contant-blk,
  .faq-terms-blk {
    a {
      color: $white;
      display: block;
      font-size: $fs-sm-default;
      min-width: 150px;
      margin-bottom: 16px;
    }
  }

  .contant-blk {
    a {
      padding-inline-start: 30px;
      &.tel {
        background: url("../../assets/images/tel.svg") no-repeat left center /
          18px 18px;
        body.lang-ar & {
          direction: ltr;
          padding-right: 30px;
          padding-left: 0;
        }
      }
      &.email {
        background: url("../../assets/images/email.svg") no-repeat left center /
          18px 18px;
      }
      body.lang-ar & {
        background-position: right center;
      }
    }
  }

  .social-blk {
    .follow-us {
      color: rgba(255, 255, 255, 0.43);
      font-size: $fs-sm-default;
    }
  }

  .social-share {
    display: flex;
    padding: 0;
    margin-bottom: 0;
    li {
      @include center-el;
      a {
        background: url("../../assets/images/social-sprites.png") no-repeat;
        height: 24px;
        width: 24px;
      }
      .icon-fb {
        background-position: -5px -5px;
        margin-inline-end: 20px;
      }
      .icon-instagram {
        background-position: -5px -39px;
        margin-inline-end: 20px;
      }
      .icon-tiktok {
        background-position: -5px -73px;
      }
    }
  }

  .bottom-blk {
    background: #f4f4f4;
    color: $grey;
    padding: 26px 0;
    text-align: center;
  }
}

@media screen and (min-width: $desktop-width) {
  footer {
    .top-blk {
      padding: 40px 0;
    }

    .contact-inner-blk {
      & > div {
        flex: 1;
      }
      div:not(:first-child) {
        padding-top: 0;
      }
      div:nth-child(1) {
        border-bottom: none;
        padding-bottom: 0;
      }
      .contant-blk {
        padding-inline-end: 30px;
      }
      .faq-terms-blk,
      .social-blk {
        padding-inline-start: 30px;
      }
    }

    .contant-blk,
    .faq-terms-blk {
      @include border(right, 1px, solid, rgba(239, 166, 147, 0.3));
      body.lang-ar & {
        border-right: none;
        @include border(left, 1px, solid, rgba(239, 166, 147, 0.3));
      }
    }

    .bottom-blk {
      padding: 30px 0;
      .rights-reserved {
        font-size: 12px;
        line-height: 2;
        body.lang-ar & {
          direction: ltr;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop-lg-width) {
  .right-blk {
    display: flex;
    justify-content: space-between;
  }

  footer {
    .contact-inner-blk {
      .contant-blk {
        padding-inline-end: 44px;
      }

      .faq-terms-blk,
      .social-blk {
        padding-inline-start: 44px;
      }
    }
  }
}
