@import "~bootstrap/scss/bootstrap.scss";
@import "./scss/main";

/**
* Common Styles
**/
html,
body,
#root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

body {
  font: {
    family: $body-font-family;
    size: $fs-default;
  }
}

.react-tel-input {
  font: {
    family: $body-font-family !important;
    size: $fs-default !important;
  }
}

.lang-ar .App {
  direction: rtl;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  li {
    list-style: none;
  }
}

button:focus,
.form-control:focus,
button:focus:not(:focus-visible) {
  box-shadow: none !important;
  outline: none !important;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"] {
  background: $white;
  @include border-full(1px, solid, #d7d7d7);
  border-radius: 4px;
  color: $grey;
  font-family: $body-font-family;
  font-size: $fs-default;
  padding: 13px 16px;
}

input[type="text"]:hover,
input[type="text"]:focus,
input[type="password"]:hover,
input[type="password"]:focus,
input[type="email"]:hover,
input[type="email"]:focus,
input[type="number"]:hover,
input[type="number"]:focus {
  @include border-full(1px, solid, $red-orange);
  background: rgba(255, 255, 255, 0.1);
  outline: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $white inset;
}

.btn-fill,
.btn-outline,
.btn-primary.btn-fill {
  @include prefix(transition, all 0.3s ease-out, webkit ms);
  border-radius: 6px;
  font: {
    family: $heading-font-family;
    size: $fs-default;
  }
  display: block;
  cursor: pointer;
  padding: 20px;
  text-align: center;
  line-height: 1;
  text-decoration: none;
}

.btn-fill,
.btn-primary.btn-fill,
.btn-primary.btn-fill:active {
  @include gradient(to right, #ea3499, 0%, #e86d32, 100%);
  color: $white;
  @include border-full(1px, solid, transparent);
  &:hover,
  &:focus {
    box-shadow: 0 12px 24px 0 rgba(233, 81, 99, 0.5);
  }
}

.btn-outline,
.btn-primary.btn-outline,
.btn-primary.btn-outline:active,
.btn-light.btn-outline,
.btn-light.btn-outline:active,
.btn-outline:hover,
.btn-outline:focus,
.btn-outline:active {
  @include border-full(2px, solid, $border-red-orange);
  background: $white;
  color: $black;
  &:hover {
    background: $red-orange;
    color: $white;
  }
}

.btn-large {
  height: 50px;
  width: 180px;
  padding: 0 !important;
  display: flex;
  align-items: center;
}
.subsection-title {
  font-family: "avertabold";
  font-size: 20px;
}

.footer-buttons {
  margin-top: 35px;
  display: flex;
  justify-content: center;
  .btn-outline,
  .btn-outline:hover,
  .btn-outline:focus {
    width: 100px;
    margin-inline-end: 20px;
  }
  .btn-fill,
  .btn-fill:hover,
  .btn-fill:focus {
    width: 199px;
  }
}

::-webkit-scrollbar {
  height: 5px;
  width: 6px;
}

::-webkit-scrollbar-track {
  height: 30px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid #d8d8d8;
  height: 20px;
  border-radius: 9999px;
  background-color: #d8d8d8;
}

::-webkit-scrollbar-track-piece:end {
  background: transparent;
  margin-bottom: 10px;
}

::-webkit-scrollbar-track-piece:start {
  background: transparent;
  margin-top: 7px;
}

.inner-content {
  flex: 1;
  padding: 24px 0 50px;
}
.opacity-less {
  opacity: 0.6;
}
.content-maintitle {
  font-family: $heading-font-family;
  font-size: 24px;
  text-align: center;
}
.modal {
  overflow-x: unset;
  overflow-y: unset;
}

.modal-content {
  max-height: 80vh;
  overflow-y: auto;
  //background: $white;
}
.dropdown-spcl-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px !important;
  height: 30px;
  padding: 5px;
  background-image: linear-gradient(to left, #ff708b, #aa76ff);
  border-radius: 50%;
  margin-inline-start: 10px;
}
@media screen and (max-width: $desktop-width) {
  .footer-buttons {
    margin-top: 35px;
    display: flex;
    position: fixed;
    justify-content: center;
    bottom: 0;
    background-color: white;
    left: 0;
    right: 0;
    /* margin-top: 20px; */
    height: 100px;
    align-items: center;
    z-index: 1;
  }
}
.option-dummy {
  padding: 20px !important;

  &:active {
    background-color: #ff708b !important;
  }
}
.meal-nodisplay {
  display: none;
}

body {
  #trengo-web-widget {
    display: none !important;
  }

  &.add-chat #trengo-web-widget {
    display: block !important;
  }
}
