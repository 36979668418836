@import "../../scss/main.scss";

.toast {
  &.toast-customize {
    position: fixed;
    top: 40px;
    right: 52px;
    background-color: $white !important;
    border-radius: 0.625rem;
    font-size: 15px;
    font-weight: normal;
    color: #212326;
    z-index: 999999;
    width: 360px;
    display: block;

    @media (max-width: 420px) {
      right: 0;
      left: 0;
      margin: auto;
      width: 310px;
    }

    .toast-header {
      position: absolute;
      top: 24px;
      right: 15px;
      background: transparent;
      border: none;
      body.lang-ar & {
        right: auto;
        left: 15px;
      }

      .btn-close {
        margin: 0;
        outline: none;
        width: 10px;
        height: 10px;
        padding: 1px;

        &:focus {
          outline: none;
          box-shadow: inherit;
        }
      }
    }

    .toast-body {
      padding: 1.375rem 2.7rem 1.375rem 1.25rem;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      line-height: 20px;
      text-align: left;

      @media (max-width: 420px) {
        font-size: 14px;
        padding-right: 3rem;
        line-height: 17px;
      }

      body.lang-ar & {
        padding: 1.375rem 1.25rem 1.375rem 2.7rem;
      }

      .icon {
        min-width: 35px;
        height: 35px;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        background: #57b07f;

        &[bg="warning"] {
          background: #ef2c2c;
        }

        body.lang-ar & {
          margin-left: 10px;
          margin-right: 0;
        }
      }
    }
  }
}
