@import "../../scss/main.scss";

/**
* Header styles
**/
header {
  @include border(bottom, 1px, solid, #e2e2e2);
  display: flex;
  padding: 17px 20px;

  .header-logo {
    width: 98px;
    height: 18px;
  }

  .primary-nav {
    display: flex;
    flex-direction: column;
    padding: 0;

    .nav-link {
      color: $black;
      padding: 0;

      &.active {
        font-family: $heading-font-family;
      }
    }

    .center-nav {
      .nav-link:nth-child(1) {
        margin-right: 40px;
      }
    }
  }

  #right-nav {
    body.lang-ar & {
      .mob-usr-home {
        text-align: right;
      }
    }
    .subscriptions {
      background: $white url("../../assets/images/icon-arrow-down.png")
        no-repeat right center;
      padding-inline-end: 30px;
      body.lang-ar & {
        background-position: left center;
      }
      &:hover,
      &.active {
        background: $white url("../../assets/images/icon-arrow-up.png")
          no-repeat right center;
      }
    }

    .tel {
      background: url("../../assets/images/tel-header.svg") no-repeat left
        center / 16px 16px;
      padding-left: 30px;
      body.lang-ar & {
        direction: ltr;
        background-position: right center;
        text-align: right;
        padding-left: 0;
        padding-right: 30px;
      }
    }

    .flag {
      color: $black;
      font-size: $fs-sm-default;
      align-items: center;
      cursor: pointer;
      display: inherit;
      // can remove below line when we launch Arabic translation
      // pointer-events: none;
      /* */

      img {
        margin-inline-end: 4px;
        height: 1.5vh;
        width: 2.5vh;
      }
    }
  }

  .account-nav,
  .user {
    @include center-align-content;
  }

  .btn-login {
    font-size: $fs-sm-default;
    padding: 14px 15px;
  }

  .user-noti {
    @include center-justify-content;
    position: relative;
  }

  .user {
    &-img {
      margin-inline-end: 6px;
    }

    &-name {
      background: $white url("../../assets/images/icon-arrow-down.png")
        no-repeat right center;
      color: $black;
      font-size: $fs-sm-default;
      padding-inline-end: 25px;
      body.lang-ar & {
        background-position: left center;
      }
    }
  }

  #user-menu {
    border-radius: 4px;
    box-shadow: 0 20px 40px 0 rgb(0 0 0 / 15%);
    position: absolute;
    top: 35px;
    left: -5px;
    padding: 0;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.26s ease;
    z-index: 5;
    width: 160px;
    body.lang-ar & {
      left: auto;
      right: -5px;
    }
    li {
      @include border(bottom, 1px, solid, #e2e2e2);

      a,
      a:hover {
        color: $black;
        font-family: $heading-font-family;
        padding: 12px 28px 12px 56px;
        body.lang-ar & {
          padding: 12px 43px 12px 15px;
        }
      }

      .submenu-link {
        background: $white url("../../assets/images/account-sprite.png")
          no-repeat 7px 9px;
        &.profile {
          background-position: 7px -27px;
        }

        &.logout {
          background-position: 7px -62px;
        }
        body.lang-ar & {
          background: $white url("../../assets/images/account-sprite-ar.png")
            no-repeat;
          background-position: calc(100% - 7px) 9px;
          &.profile {
            background-position: calc(100% - 7px) -27px;
          }
          &.logout {
            background-position: calc(100% - 7px) -62px;
          }
        }
      }
    }
    > :nth-child(3) {
      @media screen and (max-width: $tablet-width) {
        display: none;
      }
    }
  }

  .user-account:hover {
    .user-name {
      background: $white url("../../assets/images/icon-arrow-up.png") no-repeat
        right center;
      body.lang-ar & {
        background-position: left center;
      }
    }

    > #user-menu.submenu {
      transform: scaleY(1);
    }
  }

  .notification {
    background: rgba(252, 201, 173, 0.4);
    border-radius: 50%;
    cursor: pointer;
    @include center-el;
    margin-inline-start: 28px;
    position: relative;
    height: 32px;
    width: 32px;

    .icon-noti {
      background: transparent url("../../assets/images/icon-noti.svg") no-repeat
        center center / 16px 20px;
      height: 20px;
      width: 16px;
    }

    .icon-badge {
      background: transparent url("../../assets/images/icon-badge.svg")
        no-repeat center center / 16px 20px;
      border-radius: 50%;
      position: absolute;
      top: 5px;
      right: 7px;
      height: 8px;
      width: 8px;
      body.lang-ar & {
        right: auto;
        left: 7px;
      }
    }
  }

  .notification-content {
    background: $white;
    border-radius: 4px;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
    font-size: $fs-sm-default;
    padding: 20px;
    position: absolute;
    top: 45px;
    right: 0;
    max-width: 340px;
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.26s ease;

    .notification-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    .blk-title {
      font: {
        size: $fs-des-title-medium;
        family: $heading-font-family;
      }

      margin-bottom: 0;
    }

    .notification-close {
      background: url("../../assets/images/modal-close.svg") no-repeat left
        center / contain;
      padding: 0;
      height: 20px;
      width: 20px;
    }

    .nav-tabs {
      border: 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: nowrap;

      .nav-item {
        flex: 1;
      }

      .nav-link {
        border: none;
        color: $black;

        &.active {
          @include border(bottom, 3px, solid, #ea3499);
        }
      }
    }

    .tab-content {
      padding-top: 20px;
    }

    .list {
      margin-bottom: 0;

      li {
        @include border(bottom, 1px, solid, #e2e2e2);
        text-align: left;
        padding-bottom: 16px;
        margin-bottom: 16px;

        &:last-child {
          border-bottom: none;
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }

      .day {
        border-radius: 4px;
        background-image: linear-gradient(to right, #fcc9ad, #efa693);
        display: inline-block;
        padding: 7px 32px;
        margin-bottom: 12px;
      }

      .desc-blk {
        border-radius: 4px;
        background-color: $red-orange-light;
        padding: 16px;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .top-blk {
        display: flex;
        justify-content: space-between;
      }

      .title {
        font: {
          size: $fs-sm-default;
          family: $heading-font-family;
        }
      }

      .time {
        color: $grey;
      }

      .desc {
        margin-bottom: 0;
      }
    }

    &.active {
      transform: scaleY(1);
    }
  }

  .notification-blk {
    position: relative;
  }
}

.mob-menu-wrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn-mob-toggle {
    background: url("../../assets/images/icon-hamburger.svg") no-repeat left
      center / contain;
    border-radius: 0;
    height: 18px;
    width: 18px;
    body.lang-ar & {
      transform: rotate(180deg);
    }
  }

  .btn-toggle-close {
    background: url("../../assets/images/modal-close.svg") no-repeat left center /
      contain;
    padding: 0;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 30px;
  }

  #overlay {
    background: rgba(0, 0, 0, 0.48);
    left: 0;
    z-index: 30;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
  }

  .toggle-content {
    background: $white;
    box-shadow: 0 1px 3px rgb(0, 0, 0, 0.2);
    max-width: 70%;
    text-align: left;
    position: relative;
    padding: 30px;
    body.lang-ar & {
      text-align: right;
    }
  }

  #toggle-menu {
    background: $white;
    height: 100vh;
    margin-top: 40px;
  }

  .subscriptions {
    margin: 20px 0 0;
    body.lang-ar & {
      text-align: right;
    }
  }

  .submenu {
    padding-inline-start: 20px;
    .submenu-link {
      background: $white;
      color: $grey;
      font-size: $fs-sm-default;
      display: block;
      padding: 24px 0 0;
      text-align: left;
      body.lang-ar & {
        text-align: right;
      }
    }
  }

  #right-nav .tel {
    background-position: left center;
    font-family: $heading-font-family;
  }

  .flag {
    margin-inline-end: 8px;
  }
  .mob-logout {
    padding-top: 18px;
    padding-inline-start: 25px;
    // background-image:url(../../assets/images/account-sprite.png) ;
    // background-repeat: no-repeat;
    // background-position: 0px -61px;
    body.lang-ar & {
      text-align: right;
    }

    & a {
      color: $black;
      font-family: $heading-font-family;
    }
  }
}

.user-acc-mobwrap {
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px #e7e0e0;

  .btn-moblogin {
    max-width: 160px;
  }

  .mob-userimg {
    width: 50px;
    height: 50px;
    margin-inline-end: 10px;
  }

  .mob-wrapp {
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #7f7f7f;
  }

  .mob-username {
    background-image: none !important;
    font-size: 16px;
    font-family: $heading-font-family;
    width: 80%;
    padding-inline-end: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .user-mail {
    max-width: 90%;
    padding-inline-end: 10px;
    overflow: hidden;
    color: #7f7f7f;
    text-overflow: ellipsis;
    body.lang-ar & {
      text-align: right;
    }
  }
}

.mob-list-usr {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: solid 1px #e7e0e0;
}

@media screen and (min-width: $tablet-width) {
  .header-desktop {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    #logo-wrap {
      display: flex;
      flex: 0 0 20%;
    }

    #right-nav {
      display: flex;
      flex: 0 0 80%;

      .nav-link:not(.subscriptions) {
        margin-inline-end: 28px;
        &.home {
          margin-inline-end: 40px;
        }
        body.lang-ar & {
          &.tel {
            margin-left: 28px;
            margin-right: 0;
          }
        }
        
      }

      .subscriptions {
        transition: all 200ms linear;
      }

      > div {
        flex: 1;
      }

      

      .flag {
        display: flex;
        margin-inline-end: 28px;
        line-height: 1;
      }

      .notification-content .nav-link {
        margin-right: 0;
      }
    }

    .primary-nav {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      display: flex;
      position: relative;

      .nav-link {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    .submenu {
      border-radius: 4px;
      box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.15);
      position: absolute;
      top: 45px;
      left: 0;
      overflow: hidden;
      width: 240px;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform 0.26s ease;
      z-index: 99;

      .submenu-link {
        background: $white;
        color: $grey;
        display: block;
        padding: 24px 28px 0;
        text-align: left;

        &:hover {
          color: $red-orange;
        }
        body.lang-ar & {
          text-align: right;
        }
      }

      .last-link {
        padding-bottom: 24px;
      }
      body.lang-ar & {
        left: auto;
        right: 0;
      }
    }

    .subscriptions-blk {
      display: flex;
      align-items: center;
      position: relative;
      height: 100%;

      &:hover .submenu {
        transform: scaleY(1);
      }
    }

    .account-nav {
      justify-content: flex-end;
    }
  }
}

@media screen and (min-width: $desktop-width) {
  header {
    min-height: 80px;
    padding: 17px 80px;

    .notification-content {
      max-width: 375px;
    }
  }

  .btn-mob-toggle {
    display: none;
  }

  .header-desktop {
    #logo-wrap {
      flex: 0 0 30%;
    }

    #right-nav {
      flex: 0 0 70%;
    }
  }
}

@media screen and (max-width: 450px) {
  header .notification {
    margin-inline-start: 5px;
  }

  .mob-menu-wrap .toggle-content {
    max-width: 80%;
  }
}

@media screen and (max-width: $tablet-width) {
  header {
    .user-name {
      // display: none;
      font-size: 0;
    }
    .user {
      margin-inline-end: 10px;
      margin-inline-start: 10px;
    }
    .userheader {
      width: 30px;
    }
    .notification {
      margin-inline-start: 10px;
    }

    #user-menu {
      left: -52%;
      body.lang-ar & {
        left: auto;
        right: -52%;
      }
    }
  }
}
.btn.hme-dashbord-btn-mob {
  border: none;
  // text-align: center;
  @include gradient(to right, #6dcff6, 0%, #0db14b, 100%);
  border-radius: 6px;
  width: calc(100% - 5px);
  display: inline-block;
  margin-top: 6px;
  margin-bottom: 16px;

  &:hover,
  &:focus,
  &:active {
    box-shadow: 0 10px 20px 0 rgba(81, 233, 149, 0.438);
    color: $white;
  }
}
.hme-dashbord {
  text-align: center;
}
