@import "../../scss/main.scss";

/**
* Styles for modal 
**/
.modal-dialog {
  body.lang-ar & {
    direction: rtl;
  }
}

.modal-authentication {
  .modal-content {
    background: $white;
    border-radius: 20px;
  }
}

.modal-header {
  border: none;
  position: relative;
  padding: 28px 20px 9px;

  .modal-title {
    font: {
      family: $heading-font-family;
      size: $fs-modal-title;
    }

    letter-spacing: 1px;
    line-height: 1;
  }

  .btn-close {
    background: url("../../assets/images/modal-close.svg") no-repeat center;
    width: 30px;
    height: 30px;
    padding: 0;
    opacity: 1;
    position: absolute;
    right: 25px;

    body.lang-ar & {
      right: auto;
      left: 20px;
    }
  }
}

.modal-body {
  padding: 0 20px;

  .txt-grey {
    color: $grey;
    margin-bottom: 32px;

    font: {
      family: $body-font-family;
      size: $fs-default;
    }

    padding: 0;
  }

  .form-text {
    display: block;
    margin-top: 8px;
    color: $black;
  }

  .btn-primary {
    width: 100%;
    margin-top: 25px;
  }
}

.authentication-form {
  .form-group .form-control {
    // margin-bottom: 16px;
  }

  &.forgotpassword-form .form-group .form-control {
    margin-bottom: 28px;
  }

  .icon-eyes-text,
  .icon-eyes-password {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 12px;
    right: 20px;
    width: 24px;
    height: 28px;
    padding: 0;
    z-index: 5;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    body.lang-ar & {
      right: auto;
      left: 20px;
    }
  }

  .icon-eyes-text {
    background: url("../../assets/images/eye-text.svg") no-repeat center center;
  }

  .icon-eyes-password {
    background: url("../../assets/images/eye-password.svg") no-repeat center
      center;
  }

  .forgot-password {
    margin-bottom: 32px;
  }

  .back-login {
    margin-top: 20px;
  }

  .forgot-password,
  .back-login {
    .btn-link {
      color: $red-orange;
      cursor: pointer;
      margin: 0;

      font: {
        family: $heading-font-family;
        size: $fs-default;
      }

      text-decoration: none;
      padding: 0;
    }
  }

  .error {
    color: $red;
    margin-bottom: 16px;
    text-align: left;
    body.lang-ar & {
      text-align: right;
    }
  }

  &.signup-form .btn-confirm {
    margin: 0 auto;
    width: 100%;
  }

  &.signup-form .floating-label {
    transition: 0.2s ease all;

    &--empty .special-label {
      top: 50%;
      transform: translateY(-50%);
      left: 85px;
      color: #7f7f7f;
      padding: 0;

      body.lang-ar & {
        left: auto;
        right: 85px;
      }
    }

    &--active .special-label {
      display: none;
    }
  }
}

.modal-footer {
  border: none;
  padding: 0 20px 52px;

  .or-blk {
    border-bottom: 1px solid $border-grey;
    display: flex;
    height: 1px;
    align-items: center;
    justify-content: center;
    margin: 32px 0 17px;
    width: 100%;
  }

  .txt-or {
    background: $white;
    border-radius: 50%;

    font: {
      family: $body-font-family;
      size: $fs-default;
    }

    color: $black;
    padding: 5px 6px;
    position: relative;
    top: 0;
  }

  .bottom-blk {
    text-align: center;
    width: 100%;

    .btn-outline {
      width: 100%;
    }
  }

  .btn-fill {
    margin-top: 28px;
  }
}

.loading-wrp {
  z-index: 1000000;
}

@media screen and (min-width: $tablet-width) {
  .modal-header,
  .modal-body,
  .modal-footer {
    padding-left: 40px;
    padding-right: 40px;
  }

  .modal-header .btn-close {
    right: 45px;

    body.lang-ar & {
      left: 40px;
    }
  }

  .modal-authentication {
    width: 580px;

    .double {
      display: flex;
      justify-content: space-between;

      // .form-group {
      //   width: 48%;
      // }
    }
  }

  .signupdesk {
    .form-fname {
      width: 60%;
    }

    .form-lname {
      width: 35%;
    }
  }
}

/* Reset Password */
.reset-password-pg {
  .App > header,
  .App > footer,
  .toast {
    display: none;
  }
}

.reset-password {
  margin: 0 auto;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  header {
    border: none;
    margin: 64px 0;

    .header-logo {
      width: 129px;
      height: 24px;
    }
  }

  .title {
    color: #000;
    font-family: "avertabold";
    font-size: 28px;
    margin-bottom: 33px;
    line-height: 1.2;
  }

  .box-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - 44px);
    justify-content: flex-start;
    margin: auto;
    width: 320px;
  }

  .content-header,
  .content {
    text-align: center;
    width: 100%;
  }

  form,
  .full-width {
    width: 320px;
  }

  .form-group {
    position: relative;
    margin-bottom: 20px;
  }

  .form-group input[type="text"],
  .form-group input[type="password"] {
    background: #fff;
    border: 1px solid #d7d7d7;
    border-radius: 4px !important;
    color: #7f7f7f;
    font-family: "avertaregular";
    font-size: 16px;
    padding: 13px 16px;
  }

  .form-group input[type="text"]:hover,
  .form-group input[type="text"]:focus,
  .form-group input[type="password"]:hover,
  .form-group input[type="password"]:focus {
    border: 1px solid #efa693;
    background: rgba(255, 255, 255, 0.1);
    outline: none;
  }

  .form-group .icon-eyes-text,
  .form-group .icon-eyes-password {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    width: 24px;
    height: 12px;
  }

  .form-group .icon-eyes-text {
    background: url("../../assets/images/eye-text.svg") no-repeat center center;
  }

  .form-group .icon-eyes-password {
    background: url("../../assets/images/eye-password.svg") no-repeat center
      center;
  }

  .button-group {
    margin-top: 28px;

    button {
      margin: 0 auto;
    }
  }

  .icon-success {
    background: url("../../assets/images/success-icon.svg") no-repeat center
      center;
    display: inline-flex;
    width: 142px;
    height: 142px;
  }

  .btn-back-login {
    width: 100%;
  }
}

.btn-backlogin {
  background-color: $white !important;
  border: none;
  font-size: 16px;
  color: #efa693 !important;
  font-family: $heading-font-family;
  margin-top: 20px;

  &:hover {
    background-color: $white;
    border: none;
    font-size: 16px;
    color: #efa693;
  }
}

.iconformsignnup {
  position: relative;
}

.default-validation {
  position: relative;
  top: -11px;
}
