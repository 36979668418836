@font-face {
  font-family: "avertabold";
  src: url("./averta-bold/avertastd-bold.woff2") format("woff2"),
    url("./averta-bold/avertastd-bold.woff") format("woff"),
    url("./averta-bold/avertastd-bold.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "avertaregular";
  src: url("./averta-regular/avertastd-regular.woff2") format("woff2"),
    url("./averta-regular/avertastd-regular.woff") format("woff"),
    url("./averta-regular/avertastd-regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}
