@import "../assets/fonts/fonts";


@mixin center-el {
  align-items: center;
  display: flex;
  justify-content: center;
}

@mixin center-align-content {
  display: flex;
  align-items: center;
}

@mixin center-justify-content {
  display: flex;
  justify-content: center;
}

@mixin gradient($dir, $startValue,  $startPer: 0%, $endValue, $endPer: 100%) {
  background: linear-gradient($dir, $startValue $startPer, $endValue $endPer);
}

@mixin border-full($width, $style, $color) {
  border: $width $style $color;
}

@mixin border($direction, $width, $style, $color) {
  border-#{$direction}: $width $style $color;
}

@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }
  
}
.loading-wrp {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading {
      width: 3rem;
      height: 3rem;

      &.text-secondary {
          color: $white  !important;
      }
  }
}