@import "src/scss/variables.scss";
@import "src/scss/mixins.scss";

.mobileNumber {
  position: relative;

  .react-tel-input {
    .form-control {
      font-size: 16px;
      height: 52px;
      width: inherit;
      border: 1px solid #d7d7d7;
      border-radius: 4px;
      padding: 18.5px 14px 18.5px 45px;
      &:hover,
      &:focus {
        @include border-full(1px, solid, $red-orange);
      }

      body.lang-ar & {
        padding: 18.5px 45px 18.5px 14px;
        direction: ltr;
        text-align: right;
      }
    }

    .special-label {
      color: #7f7f7f;
      width: fit-content;
      font-size: 16px;
      left: 13px;
      top: -11px;

      body.lang-ar & {
        left: 0;
        right: 13px;
        text-align: right;
      }
    }

    .selected-flag {
      border-radius: 4px 0 0 4px;
      body.lang-ar & {
        padding: 0 11px 0 0;
      }
    }

    .num-error,
    .num-error:active,
    .num-error:focus,
    .num-error:hover {
      box-shadow: 0px 0px 0px 1px rgb(239, 44, 44) !important;
    }
  }

  &.apply-error .special-label {
    color: $red;
  }

  &-mobile {
    .react-tel-input {
      .form-control {
        font-size: 14px;
        height: 52px;
        padding: 18.5px 14px 2px 45px;

        body.lang-ar & {
          padding: 18.5px 45px 2px 14px;
        }
      }

      .special-label {
        font-size: 12px;
        top: 6px;
      }

      .selected-flag .flag {
        margin-top: -6px;
        margin-left: 6.5px;
      }
    }
  }
}
